*,*::before,*::after{
    box-sizing: border-box;
}
:root{
    --color-base:#364e62;
    --color-second:#e74c2a;
    --color-background:#eee;
    --height-banner:7rem;
}

.admin-content{    
    position: absolute;
    display: inline-block;  
    background-color: var(--color-background) ;     
    width: 97vw;
    height: 100vh;
    min-height: 500px;
    margin-top: var(--height-banner);  
    padding-left: 15%;
    margin-left: 1.6%;   
    padding-top: 0.5em;

}
.footer{
    margin-top: 2%;
    text-align: center;
    font-size: 14px;
    color:var(--color-base);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    vertical-align: bottom;
    text-decoration-line:underline;        

}
.footer span{
    color:var(--color-second);
}
.admin-menu_left{
    position: fixed;
    display: inline-block;
    /*overflow-y: scroll;   */ 
    width: 16%;
    height: 90vh;   
    padding-bottom: 2px;
    margin-left: 0;    
    background: var(--color-base);    
    z-index: 9;
    margin-top: var(--height-banner);
}
.admin-barra{
    display: inline-block;
    position: fixed;
    z-index: 10; 
    background: var(--color-base);
    width: 14.8%;
    height: 7%;
    margin-top: 7rem;
    margin-bottom: 0;
    margin-left: 0;
    box-shadow: 0 8px 10px -5px rgba(0,0,0,.8);
}
#circle:hover{
    cursor:pointer;
    box-shadow: 0 0 5px 20px var(--color-second) inset;
}
.circlen{
    display: inline-block;
    position: fixed;
    z-index: 11;
    margin-top: 7.1rem;
    margin-left: 12%;
    width: 35px;
    height: 35px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 8px 10px -5px rgba(0,0,0,.9);
}
.circlep{
    display: inline-block;
    position: fixed;
    z-index: 11;
    margin-top: 7.1rem;
    margin-left: 0.5%;
    width: 35px;
    height: 35px;
    background-color: var(--color-base);
    border-radius: 50%;
    box-shadow: 0 8px 10px -5px rgba(0,0,0,.9);
}
.next{
    display: inline-block; 
    position: fixed;
    z-index: 12;
    margin-top: 0.4%;
    padding-left:0; 
    width: 12px;
    height: 12px;
    border: 12px solid;
    border-color: transparent var(--color-base) transparent transparent; 
}
.prev{
    position: fixed;
    z-index: 12;
    margin-top: 0.4%;
    margin-left: 0.9%;
    display: inline-block;  
    width: 12px;
    height: 12px;
    border: 12px solid;
    border-color: transparent transparent transparent white; 
}
.admin-menu_left p{
    margin-top: 1%;
    color: white;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 8px;
}
.admin-text{
    display: inline-block;
}

.admin-vertical-menu {   
     width: 90%;     
     margin-left: 5%;    
}

.admin-vertical-menu a {
    color: white; 
    display: block; 
    padding: 8px;
    text-decoration: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif; 
    font-size: 1em;   
}
.admin-vertical-menu a:hover {
    background-color: #ffbb8d; 
    color:var(--color-base);
    font-weight: bold;
    font-size: 1.2em;
}
.admin-vertical-menu a.active {
    display: inline-block;
    background-color: var(--color-second);   
    width: 100%;
    color: var(--color-base);
    font-weight: bold;
    font-size: 1em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;  
    box-shadow: 0 8px 10px -5px rgba(0,0,0,.8);

}
.admin-menu-icon{
    display: inline-block;
    color: white;
    width: 30px; 

}
.admin-vertical-space{
    height: 10%;

}
#icon_blue{
    color: var(--color-base);
}
.admin-aling{
    font-size: 15px;
    margin-right: 8%;
    margin-left: 2%;
}
.admin-botones a{
    display:inline-block;
    width: 35px;
    height: 35px;
    font-size: 14px; 
    text-align: center;
    padding-top: 0.5%;
    border: 0.5px solid var(--color-base);       
    background-color: transparent;   
    color: var(--color-base);
    text-decoration: none;
    border-radius: 40%;
    margin-left: 0.3%;
}
.admin-botones a:hover{
    cursor:pointer;
}
a.admin-active{
    font-size: 14px; 
    font-weight: bold;
    border: 2px solid var(--color-base); 
    background-color: #ffbb8d;

} 
.admin-register{
    height: 89%; 
    width: 100%;  
    background-color: var(--color-base);    
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;   
    color:white;  
}

.admin-form{
    width: 60%;
    padding-top: 2%;
}

.admin-form label{
    display: block;
}
.admin-form input{   
    margin-bottom: 1em;  
    display: inline-block;
    background: white;
    width: 50%;
    height: 58px;
    border-radius: 5px;
    border: inherit;

}
.admin-form button{
    background: var(--color-second);
    color: white;
    /*width: 100%;
    height: 15%;*/
    width: 50%;
    height: 58px;
    border-radius: 5px;
    margin-top: 1em;
    font-size: 18px;
    font-weight: bold;
    border: inherit;
}
.admin_img_company{
    width: 80px;
    height: 80px;
    margin-top: 2%;
}
.admin_error{
    width: 50%;    
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    padding-bottom: 0;
    text-align: center;
    font-weight: bold;
}
.admin-text-important{
    font-size: 0.6em;
    padding-bottom: 0;
    text-align: center;
    margin-bottom: 0;
}
.admin-logout{
    display: block;
    width: 200px;  
    color: white;    
    margin-top: 3%;
    margin-left: 84%; 

}
.admin-logout a{
    color: white;  
}
.admin-logout a:hover{
    text-decoration: none;
}
.admin_icon_logout{
    font-size: 2em; 
    display: inline;
}
.admin_icon_logout:hover{
    color: var(--color-second);
    display: inline;
}
.text-footer{
    padding-top: 1rem;
}

/* home Admin*/
.home-admin{
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.home-admin__menu{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap:20px;
    margin-top: 50px;
    margin-bottom: 120px;
}














