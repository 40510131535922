.stepwizard-step p {
    margin-top: 10px;
}
/*.stepwizard-row {
    display: table-row;
}*/

/*.steps-wrapper {
    display: table-row;
}
.stepwizard {
    display: table;
    margin-bottom: 30px;
}
.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}*/
/*.stepwizard-row:before {
    top: 25px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 10px;
    background-color: #ccc;
}*/
/*.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
}

.stepwizard-step p {
    font-size: 11px;
}*/

.steps-wrapper li {
    list-style-type: none;
    float: left;
    width: 25%;
    position: relative;
    text-align: center;
}

.steps-wrapper li:before {
    content: attr(data-content);
    line-height: 60px;
    border-radius: 50%;
    width: 60px;
    height: 60px !important;
    border: 1px solid #eee;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: #eee;
}

.steps-wrapper li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    background-color: #ccc;
    top: 25px;
    left: -50%;
    z-index: -1;
}

.steps-wrapper li:first-child:after {
    content: none;
}



/*.btn-circle {
    width: 60px;
    height: 60px !important;
    text-align: center;
    padding: 10px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 50% !important;
}

.stepwizard-step a.disabled {
    background-color: white;
    opacity: 1 !important;
}

a.btn-circle i{
    margin-top: -5px;
}

a.btn-circle i.sucessStep {
    color: green;
}

a.btn-circle i.failedStep {
    color: red;
}*/

div.note_erp, div.note_monitoring{
    border-radius: 15px;
     -moz-border-radius: 15px;
     -webkit-border-radius: 15px;
     padding: 1.5rem;
     /* background-position: 9px 0px;
     background-repeat: no-repeat; */
     /* line-height: 1.2em !important; */
     /* background-image: url(../../public/img/payments/css-box-icon-1.png); */
     /* font-size: 1.0em; */
}
div.note_erp h4, div.note_monitoring h4, div.note_erp h3, div.note_monitoring h3{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

}
div.note_erp {
    border: solid 1px rgb(69, 96, 122);
}

div.note_monitoring {
    border: solid 1px #e74c2a;
}

.pay-boxes
{
    border: solid 1px #e74c2a !important;
    border-radius: 6px;
}

.list-group-item
{
    border: 1px solid #fff;
}

/* #table_summary_monitoring tr td, #table_summary_monitoring tr th{
    #border: 0.5px solid #ef6603;
}

#table_summary_monitoring td {
    #border-top: 1px solid #ef6603;
    border-top: none !important;
}

#table_summary_monitoring thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ef6603 !important;
    border-top: 1px solid #ef6603 !important;
}

#table_summary_monitoring tbody + tbody {
    border-top: 2px solid #ef6603 !important;
}

#table_summary_erp tr td, #table_summary_erp tr th{
    #border: 0.5px rgb(69, 96, 122);
}

#table_summary_erp td {
    #border-top: 1px solid rgb(69, 96, 122);
    border-top: none !important;
}

#table_summary_erp thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgb(69, 96, 122) !important;
    border-top: 1px solid rgb(69, 96, 122) !important;
}

#table_summary_erp tbody + tbody {
    border-top: 2px solid rgb(69, 96, 122) !important;
} */

#prod_pending_monitoring {
    color: rgb(247, 40, 0);
    font-weight: bold;
}

#prod_pending_erp {
    color: rgb(39, 55, 70);
    font-weight: bold;
}

.table_summary_pending_payment{
    padding: 1.5rem;
}

.table_summary_pending_payment th{
    vertical-align: middle !important;
}

.table_summary_pending_payment td{
    border-top: none;
}


.table_summary_pending_payment h4 {
    font-size: 2rem;
}

#pending_payment_tfoot tr:first-child td{
    padding-top: 3rem;
}

#total_pending_payment{
    color: rgb(247, 40, 0);
}

#pending_payment_tfoot tr:first-child td{
    border-top: rgb(153,148,154) 1px solid !important;
  }
/* Media query for mobile viewport */

@media screen and (max-width: 400px) {
    #paypal-button-container {
        width: 100% !important;
    }
    /*#payment_recurrent_li_1 {
        width: 100%;
        margin: 0 auto 20px auto;
    }*/
}
        
/* Media query for desktop viewport */

@media screen and (min-width: 400px) {
    #paypal-button-container {
        max-width: 250px !important;
        margin: 0 auto 20px auto;
    }
    /*#payment_recurrent_li_1 {
        width: 250px;
        margin: 0 auto 20px auto;
    }*/
}