@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;

0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url(~tooltipster/dist/css/tooltipster.bundle.min.css);
@import url(~tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-shadow.min.css);
@import url(~bootstrap/dist/css/bootstrap.min.css);
@import url(~chart.js/dist/Chart.min.css);
@import url(~jquery-ui/themes/base/all.css);
@import url(~jquery-ui/themes/base/dialog.css);
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("~material-icons/iconfont/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("~material-icons/iconfont/MaterialIcons-Regular.woff2") format("woff2"), url("~material-icons/iconfont/MaterialIcons-Regular.woff") format("woff"), url("~material-icons/iconfont/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

